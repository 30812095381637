<template>
  <div class="px-4 py-1 stop">
    <div class="py-1 font-bold text-black border-b border-gray-300 title">
      {{ title }}
    </div>
    <div class="pt-2 content_item">
      <div class=" items-center justify-between">
        <div style='width:100%' class=" items-center item">

            <div style="width:80px;float:left;margin-left:00px;line-height: 50px;">订单编号：</div>
            <div  style="float:left;width:100px" class="py-2 pr-4 input_box">
              <a-input size="small" style="width:100px;height:30px" placeholder="请输入订单编号" v-model="put_ordersn" />
            </div>
            <div style="width:80px;float:left;margin-left:00px;line-height: 50px;">商户名称：</div>
            <div  style="float:left;width:100px" class="py-2 pr-4 input_box">
              <a-input size="small" style="width:100px;height:30px" placeholder="请输入确认码" v-model="merchant_title" />
            </div> 
            <div style="width:80px;float:left;margin-left:00px;line-height: 50px;">商品名称：</div>
            <div  style="float:left;width:100px" class="py-2 pr-4 input_box">
              <a-input size="small" style="width:100px;height:30px" placeholder="请输入确认码" v-model="good_name" />
            </div>
                       <div style="width:180px;float:left;margin-left:00px;line-height: 50px;"> 
                        <a-checkbox v-model:checked="checked">查看逾期未执行订单</a-checkbox></div>

            <div style="width:80px;clear:both;float:left;margin-left:00px;line-height: 50px;">确认码：</div>
            <div  style="float:left;width:100px" class="py-2 pr-4 input_box">
              <a-input size="small" style="width:100px;height:30px" placeholder="请输入确认码" v-model="code" />
            </div>
                        <div style="width:80px;float:left;margin-left:00px;line-height: 50px;">执行状态：</div>
            <div  style="float:left;width:100px;height:30px" class="py-2 pr-4 input_box">
               <a-select
              ref="select"
              v-model:value="value1"
              style="width: 100px;height:30px"
              
              @change="handleChange"
              >
              <a-select-option value="">全部</a-select-option>
                <a-select-option value="-1">已删除</a-select-option>
                <a-select-option value="0">待采集</a-select-option>
                <a-select-option value="1">已取消</a-select-option>
                      <a-select-option value="2">已采集</a-select-option>
                <a-select-option value="3">待采中</a-select-option>
                      <a-select-option value="4">已上传</a-select-option>            
              </a-select>
            </div>
                        <div style="width:80px;float:left;margin-left:00px;line-height: 50px;">订单状态：</div>
            <div  style="float:left;width:100px" class="py-2 pr-4 input_box">
                             <a-select
              ref="select"
              v-model:value="value2"
              style="width: 100px;height:30px"
              
              @change="handleChange2"
              >
              <a-select-option value="">全部</a-select-option>
              <!-- <a-select-option value="0">未付款</a-select-option> -->
                <a-select-option value="1">已付款</a-select-option>
                <a-select-option value="2">已退款</a-select-option>
                 
              </a-select>
            </div>
          <div style="width:500px;float:right;height:60px;margin-top:-40px;"> 
          <a-button  style='float:right;width:48px;background-color:#294DEA;color:#fff' size="small" type="" @click="resetFunc" class="ml-2"
            >重置</a-button>
          <a-button @click= 'aquireLists'  style='float:right;width:48px;background-color:#294DEA;color:#fff' size="small" type="">查询</a-button>
 
          
          <div style='float:right;margin-top:-15px' class="pt-2 input_box">
            <div class="py-2 pr-4 input_box">
              <a-range-picker
                size="small"
                style="width: 250px;height:50px;"
                :placeholder="['开始日期', '结束日期']"
                :value="showDateTime"
                @change="onChange"
              >
                <template slot="dateRender" slot-scope="current">
                  <div
                    class="ant-calendar-date"
                    :style="getCurrentStyle(current)"
                  >
                    {{ current.date() }}
                  </div>
                </template>
              </a-range-picker>
            </div>
          </div>
          <div style='float:right;'>采集时间：</div>
          </div> 
        </div>
      </div>
      <div style = 'background-color:#fff;margin-top:100px;' class="pt-4">
        <a-table
          :data-source="dataSource"
          :columns="columns"
          :scroll="{ x: 1000 }"
          :pagination="pagination" 
        >
        <template style="" slot="order_state" slot-scope="text, record">
          <!-- {{record.refund_status_text}}   -->
         
          <div  v-if="record.refund_status==0">{{record.refund_status_text}}正常</div>
          <a-button 
                type="default"
                v-if="record.refund_status==1"
                @click="order_show(record.id,1)"
                >退款审核中</a-button>
                <a-button 
                type="default"
                v-if="record.refund_status==2"
                @click="order_show(record.id,2)"
                >已退款</a-button>
                <a-button 
                type="default"
                v-if="record.refund_status==3"
                @click="order_show(record.id,3)"
                >拒绝退款申请</a-button>
          <!-- {{record.name}} -->
            
          </template>
          <template style="width:220px;" slot="operation" slot-scope="text, record">
            <div v-if="record.statusName!='已上传'" class="flex">
              <a-button
                type="default"
                class="mr-2"
                @click="collectData(record.key,'','reset')"
                >采集</a-button
              >
            </div>

            <a-popconfirm
              v-if="1"
              title="是否重新拍摄?如已上传将删除已经拍摄照片"
              cancelText="取消"
              okText="确定"
              @confirm="() => repateData(record.key)"
            >
            <div v-if="record.statusName=='已上传'" class="flex">
              <a-button
                type="default"
                class="mr-2"
                
                >重拍</a-button
              >
            </div>
            </a-popconfirm>
          </template>
        </a-table>
      </div>
      <a-modal cancelText="关闭"   v-model:visible="visible" title="退款信息" >
      <p>{{refund_explain}}</p>
      <!-- <p>{{refund_status_text}}</p> -->
        <template slot = 'footer'>
          <a-button @click="handCancelgb">关闭</a-button>
        </template>
      </a-modal>
      <a-modal cancelText="拒绝" okText="同意" v-model:visible="visiblech" title="退款信息" @cancel="" @ok="">
      <p>{{refund_explain}}</p>
      <!-- <p>{{refund_status_text}}</p> -->
      <template slot = 'footer'>
          <a-button  @click="handCancel">拒绝</a-button>
          <a-button style="background-color:#509645;color:#fff" @click="handleOk">同意</a-button>
        </template>

      </a-modal>
    </div>
   
  </div>
</template>

<script>
import {updateStatus, collectList,repateStatus,passRefund,refundInfo,refuseRefund } from "@/api/collect";

import { transformDate } from "@/utils/transformDate";

export default {
  data() {
    return {
      value1:'',
      value2:'',
      refund_explain:'',
      put_ordersn:'',
      merchant_title:'',
      good_name:'',
      code:'',
      checked: false,
      visiblech:false,
      refund_status_text:'',
      showtype:'',
      visible:false,
      title: "",
      passid:0,
      titleObj: {
        local: "本地申请数据分析",
        web: "网页申请数据分析",
        month: "本月申请数据分析",
        year: "本年申请数据分析",
      },
      orderson:'',
      merchant_title:'',
      timearr:[],
      type: 2,
      typeObj: {
        local: 3,
        web: 4,
        month: 5,
        year: 6,
      },
      order: "",
      dataSource: [
        // {
        //   key: "0",
        //   number: "18746124516451986",
        //   date: "2020-12-12 14:30~15：30",
        //   model: "男上衣",
        //   type: "上衣",
        //   encipher: "B00101",
        //   size: "S",
        //   status: "采集中",
        // },
        // {
        //   key: "1",
        //   number: "18746124516451986",
        //   date: "2020-12-12 14:30~15：30",
        //   model: "男上衣",
        //   type: "上衣",
        //   encipher: "B00101",
        //   size: "S",
        //   status: "未采集",
        // }
      ],
      columns: [
        {
          title: "订单编号",
          dataIndex: "ordersn",
          // fixed: "left",
          width: 200,
        },
        { title: "商户名称", dataIndex: "member_name", width: 130 },
        {
          title: "采集时间",
          dataIndex: "dateTime",
          width: 200,
        },
        {
          title: "采集机型",
          dataIndex: "name",
          width: 80,
        },
        {
          title: "机器人名称",
          dataIndex: "type_name",
          width: 80,
        },
        {
          title: "编码",
          dataIndex: "code",
          width: 80,
        },
        {
          title: "规格",
          dataIndex: "size",
          width: 50,
        },
                
        {
          title: "订单状态",
          dataIndex: "status",
          width: 100,
       
          key: "status",
          fixed: "right",
      
          scopedSlots: { customRender: "status" },
          customRender: (text,record)=>{
            if(text ==0){
              return '未付款'
            }else if(text ==1){
              return '已付款'
            }else if(text ==2){
              return '已退款'
            }else{
            return text 
            }
            
          },
        },
        {
          title: "采集状态",
          dataIndex: "order_status_text",
          fixed: "right",
          width: 120,
          filters: [
            {
              text: "未采集",
              value: "未采集",
            },
            {
              text: "采集中",
              value: "采集中",
            },
            {
              text: "已采集",
              value: "已采集",
            },
          ],
          // filterDropdownVisible:true,
         // onFilter: (value, record) => record.statusName.indexOf(value) === 0,
        },

        {
          title: "操作",
          key: "operation",
          fixed: "right",
          width: 90,
          scopedSlots: { customRender: "operation" },
        },
      ],
      statusNameObj: {
        "-1": "已删除",
        0: "采集中",
        1: "已取消",
        2: "已上传",
      },
      pagination: {
        // 关于页码的配置属性
        total: 0,
        pageSize:6,
        pageNo: 1,
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["10", "20", "50", "100"], // 每页数量选项
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) =>
          this.onSizeChange(current, pageSize), // 改变每页数量时更新显示
        onChange: (page, pageSize) => this.onPageChange(page, pageSize), //点击页码事件
      },
      searchTime: [], // 查询的时间
      showDateTime: null, //默认给个null
    };
  },
  created() {
    let { type } = this.$route.query;
    this.title = this.titleObj[type];
    this.type = this.typeObj[type];
    this.aquireList();
  },
  watch: {
    $route: function() {
      let { type } = this.$route.query;
      this.title = this.titleObj[type];
      this.type = this.typeObj[type];
      this.aquireList();
    },
  },
  methods: {
    handleChange(e){
      console.log(e)
      this.value1 = e
    },
    handleChange2(e){
      console.log(e)
      this.value2 = e
    },
    order_show(id,type){
      this.passid = id
      refundInfo({id:id}).then(res=>{
        if(res.code == 200){
          this.refund_explain = res.data.refund_explain
          this.refund_status_text = res.data.refund_status_text
        }
      })
      this.showtype = type
      if(type ==1){
        this.visiblech = true
      }
      if(type ==2 || type ==3){
        this.visible = true
        
      }

  
    },
    handCancelgb(){

      this.visible = false
    },
    handCancel(){
      this.visiblech = false
      refuseRefund({id:this.passid}).then(res=>{
        if(res.code ==200){
          this.$message.success("拒绝成功");
          location.reload()
        }else{
          this.$message.error("拒绝失败");
        }
      })
    },
    handleOk(){
      this.visiblech = false
      
      passRefund({id:this.passid}).then(res=>{
        if(res.code ==200){
          this.$message.success("同意成功");
          
          location.reload()
        }else{
          this.$message.error("同意失败");
        }
      })
},
    showConfirm(){
      Modal.confirm({
        title: () => 'Do you want to delete these items?',
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () => 'When clicked the OK button, this dialog will be closed after 1 second',
        onOk() {
          return new Promise((resolve, reject) => {
            setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          }).catch(() => console.log('Oops errors!'));
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onCancel() {},
      });
    },

    repateData(key){  
            let index = this.dataSource.findIndex((item) => {
        return item.key == key;
      });
      let data = this.dataSource[index];
      console.log(data.id)
      //this.showConfirm()            
                  repateStatus({order_id:data.id,action:'reshoot'}).then(res=>{
                     console.log(res)
                     console.log(key)
                     if(res.code == 200){
                     this.collectData(key,data.id,'reset')
                     }else{
                      alert(res.msg)
                     }
                  })


    },


    getCurrentStyle(current, today) {
      const style = {};
      if (current.date() === 1) {
        style.border = "1px solid #1890ff";
        style.borderRadius = "50%";
      }
      return style;
    },
     // 搜索请求数据
      aquireLists() {
       
        if(this.checked == true){
          this.type=8
        }else{
          this.type=3
        }
        
      let data = {
        type: this.type,
        order_status:this.value1,
        status:this.value2,
        page: this.pagination.pageNo,
        limit: this.pagination.pageSize,
        ordersn :this.put_ordersn,
        good_name:this.good_name,
        code:this.code,
        merchant_title :this.merchant_title,
        time:this.timearr
      };
      this.searchTime.length > 0 ? (data.time = this.searchTime) : "";
      collectList(data)
        .then((res) => {
          res.data.list.forEach((item, index) => {
            item.key = index;
            item.statusName = this.statusNameObj[item.order_status];
            item.dateTime = `${item.date}  ${item.start_time} ~ ${item.end_time}`;
          });
          this.dataSource = [];
          this.dataSource.push(...res.data.list);
          this.pagination.total = res.data.count;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 请求数据
    aquireList() {
      let data = {
        type: this.type,
        page: this.pagination.pageNo,
        limit: this.pagination.pageSize,
        ordersn :this.orderson,
        merchant_title :this.merchant_title,
        time:this.timearr
      };
      this.searchTime.length > 0 ? (data.time = this.searchTime) : "";
      collectList(data)
        .then((res) => {
          res.data.list.forEach((item, index) => {
            item.key = index;
            item.statusName = this.statusNameObj[item.order_status];
            item.dateTime = `${item.date}  ${item.start_time} ~ ${item.end_time}`;
          });
          this.dataSource = [];
          this.dataSource.push(...res.data.list);
          this.pagination.total = res.data.count;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    change(e) {
      // console.log(e)
      this.page = e.current;
      this.aquireList();
    },
    // 分页里size改变触发的函数
    onSizeChange(_, pageSize) {
      this.pagination.pageNo = 1;
      this.pagination.pageSize = pageSize;
      this.aquireList();
    },
    // 页面改变时
    onPageChange(page, pageSize) {
      console.log(page, pageSize);
      this.pagination.pageNo = page;
      this.aquireList();
    },
    // 跳转到采集页面（数据录入）
    collectData(key,dataid,action) {
      let index = this.dataSource.findIndex((item) => {
        return item.key == key;
      });
      let data = this.dataSource[index];
      console.log(data)
      this.$store.commit("setOrderInfo", {
        sn: data.ordersn,
        id: data.order_id,
        size: data.size,
      });
      
      localStorage.setItem(
        "orderInfo",
        JSON.stringify({
          sn: data.ordersn,
          id: data.id,
          size: data.size,
        })
      );
      if(action == 'reset'){
        localStorage.setItem(
            "orderInfo",
            JSON.stringify({
              sn: data.ordersn,
              id: data.id,
              size: data.size,
              order_id: data.order_id,
            })
          );
          //alert(data.order_id)
          this.$router.push("/edit");
      }else{
        updateStatus({
        order_id: dataid,
        order_status: 0,
      }).then((res) => {
        if (res.code == 200) {
          localStorage.setItem(
            "orderInfo",
            JSON.stringify({
              sn: data.ordersn,
              id: data.id,
              size: data.size,
              order_id: dataid,
            })
          );
          this.$router.push("/edit");
        } else {
          alert('返回结果失败')
          this.$message.error(res.msg);
        }
      });
      }

      // params: { order_id: data.order_id, size: data.size }

    },
    resetFunc() {
      this.pagination.pageNo = 1;
      this.searchTime = [];
      this.showDateTime = null;
      this.aquireList();
    },
    onChange(e) {
      this.showDateTime = e;
      let start = transformDate(e[0]);
      let end = transformDate(e[1]);
      this.searchTime = [];
      this.searchTime.push(start);
      this.searchTime.push(end);
      this.aquireList();
    },
  },
};
</script>

<style lang="less" scoped>
.ant-input-sm{height: 30px !important;}
.title {
  font-size: 20px;
}
.input_box {
  width: 250px;
  margin-right: 20px;
}

.content_item_title {
  font-size: 16px;
  color: #000000;
}
.select_info {
  background: #afe8bc;
  .etc-gantanhao-copy-copy {
    font-size: 18px;
  }
  span {
    font-size: 14px;
  }
  i {
    font-style: normal;
    padding: 0px 2px;
    color: @primary-color;
  }
  .delete {
    color: red;
    user-select: none;
  }
}
</style>
<style lang="less">
.stop {
  .circle {
    display: inline-block;
    height: 6px;
    width: 6px;
    background: @primary-color;
    border-radius: 50%;
  }
  tr td:last-child {
    display: flex;
    justify-content: flex-end;
    a:nth-child(1) {
      color: @primary-color;
    }
    a:nth-child(2) {
      color: red;
    }
  }
  th:last-child {
    text-align: right;
  }

  .ant-btn {
    display: flex;
    align-items: center;
  }
}
</style>
